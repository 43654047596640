<template>
  <div>
    <top-menu></top-menu>
    <section id="container">
      <left-menu></left-menu>
      <section id="content">
        <section class="studybox_area">
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>알림</h2>
          </div>
          <section class="study_myinfo_list_area" >
            <div class="mypage_scroll_content" style="width:100%;margin-top:30px; ">
              <section class="study_myinfo_list_box">
                <!-- content -->
                <!-- 중요공지 -->
                <div class="notifi_wrap_01">
                  <div class="notifi_title_box01">
                    <h4>중요공지</h4>
                  </div>
                  <ul class="notifi_wrap_list">
                    <li>
                      <a href="#">10000point 충전되었습니다.</a>
                    </li>
                    <li>
                      <a href="#">10000point 충전되었습니다.</a>
                    </li>
                    <li>
                      <a href="#">10000point 충전되었습니다.</a>
                    </li>
                    <li>
                      <a href="#">10000point 충전되었습니다.</a>
                    </li>
                  </ul>
                  <div class="notifi_wrap_bottom_page">
                    <div class="notifi_wrap_bottom_left"></div>
                    <ul>
                      <li>1</li>
                      <li>2</li>
                      <li>3</li>
                    </ul>
                    <div class="notifi_wrap_bottom_right"></div>
                  </div>
                </div>
                <!-- //중요공지 -->
                <!-- 학습 알림 -->
                <div class="notifi_wrap_02">
                  <div class="notifi_title_box02">
                    <h4>학습알림</h4>
                  </div>
                  <ul class="notifi_wrap_list">
                    <li>
                      <a href="#">10000point 충전되었습니다.</a>
                    </li>
                    <li>
                      <a href="#">10000point 충전되었습니다.</a>
                    </li>
                    <li>
                      <a href="#">10000point 충전되었습니다.</a>
                    </li>
                    <li>
                      <a href="#">10000point 충전되었습니다.</a>
                    </li>
                    <li>
                      <a href="#">10000point 충전되었습니다.</a>
                    </li>
                    <li>
                      <a href="#">10000point 충전되었습니다.</a>
                    </li>
                    <li>
                      <a href="#">10000point 충전되었습니다.</a>
                    </li>
                    <li>
                      <a href="#">10000point 충전되었습니다.</a>
                    </li>
                  </ul>
                  <div class="notifi_wrap_bottom_page">
                    <div class="notifi_wrap_bottom_left"></div>
                    <ul>
                      <li>1</li>
                      <li>2</li>
                      <li>3</li>
                    </ul>
                    <div class="notifi_wrap_bottom_right"></div>
                  </div>
                </div>
                <!-- //학습 알림 -->
                <!-- 포인트 알림 -->
                <div class="notifi_wrap_03">
                  <div class="notifi_title_box03">
                    <h4>포인트 알림</h4>
                  </div>
                  <ul class="notifi_wrap_list">
                    <li>
                      <a href="#">10000point 충전되었습니다.</a>
                    </li>
                    <li>
                      <a href="#">10000point 충전되었습니다.</a>
                    </li>
                    <li>
                      <a href="#">10000point 충전되었습니다.</a>
                    </li>
                    <li>
                      <a href="#">10000point 충전되었습니다.</a>
                    </li>
                    <li>
                      <a href="#">10000point 충전되었습니다.</a>
                    </li>
                    <li>
                      <a href="#">10000point 충전되었습니다.</a>
                    </li>
                    <li>
                      <a href="#">10000point 충전되었습니다.</a>
                    </li>
                    <li>
                      <a href="#">10000point 충전되었습니다.</a>
                    </li>
                  </ul>
                  <div class="notifi_wrap_bottom_page">
                    <div class="notifi_wrap_bottom_left"></div>
                    <ul>
                      <li>1</li>
                      <li>2</li>
                      <li>3</li>
                    </ul>
                    <div class="notifi_wrap_bottom_right"></div>
                  </div>
                </div>
                <!-- //포인트 알림 -->
                <!-- //content -->
              </section>
            </div>

          </section>
        </section>
        <footer-navigation :back="{ name: 'my-page' }"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>
import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import MainFooter from '@/layouts/components/Footer'
import ContentFooter from '@/layouts/components/ContentFooter'

export default {
  name: 'MyPage.Notification',
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    ContentFooter
  }
}
</script>

<style scoped>

</style>
